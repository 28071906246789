import { Fragment } from "react"
import { styled } from "@mui/material/styles"
import {
  Divider,
  List,
  ListItemText,
  ListItemAvatar,
  Typography,
  Box,
  ListItemButton,
} from "@mui/material"
import { Navigate } from "react-router-dom"

import * as shared from "probuild-shared"

import PageHeader from "components/views/generic/PageHeader"
import AuthPageLayout from "components/views/layouts/AuthPageLayout"
import useAuthAccountSetup from "model/auth/useAuthAccountSetup"
import { ArrowForwardIos, SupportAgent } from "@mui/icons-material"
import LoadingView from "components/views/LoadingView"
import ErrorView from "components/views/ErrorView"
import LoadingImage from "components/views/generic/LoadingImage"
import paths from "model/utils/paths"

const ContentDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
}))

const LogoImage = styled(LoadingImage)(({ theme }) => ({
  width: "60px",
  height: "60px",
  backgroundColor: theme.palette.background.paper,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderWidth: "2px",
  borderRadius: "10px",
  objectFit: "contain",
}))

const AuthAccountSetupPage = () => {
  const [viewData, input] = useAuthAccountSetup()

  if (!viewData || !input) {
    return null
  }
  if (viewData.navigateBackToPhoneNumberEntry) {
    return <Navigate to={paths.authPhone()} replace={true} />
  }
  if (viewData.navigateToTeamKey) {
    return (
      <Navigate to={paths.team(viewData.navigateToTeamKey)} replace={true} />
    )
  }
  if (viewData.navigateToSupportAgentHome) {
    return <Navigate to={paths.supportAgentFeed()} replace={true} />
  }
  const accounts = viewData.accounts?.asJsReadonlyArrayView()
  return (
    <AuthPageLayout title={viewData?.title}>
      {viewData && (
        <ContentDiv>
          <PageHeader title={viewData?.title} subtitle={viewData.subtitle} />
          <List sx={{ mx: 1 }}>
            <Divider />
            {accounts.map((account) => (
              <AuthAccountListItem
                viewData={account}
                onClick={() =>
                  input?.selectAccount(account.teamKey, account.isSupportAgent)
                }
              />
            ))}
            {viewData.empty && (
              <Typography sx={{ p: 2 }} align="center">
                {viewData.empty}
              </Typography>
            )}
            {viewData.isLoading && <LoadingView />}
          </List>
          {viewData.error && <ErrorView description={viewData.error} />}
        </ContentDiv>
      )}
    </AuthPageLayout>
  )
}

const AuthAccountListItem = ({
  viewData,
  onClick,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.auth.data.view.AuthAccountSetupListItemViewData
  onClick: () => void
}) => {
  return (
    <Fragment key={viewData.viewKey}>
      <ListItemButton
        onClick={() => onClick()}
        disabled={!viewData.isSelectable}
      >
        <ListItemAvatar sx={{ pr: 2 }}>
          <LogoImage src={viewData.logoUrl} alt={viewData.teamName} />
        </ListItemAvatar>
        <ListItemText
          primary={viewData.teamName}
          secondary={viewData.userName}
        />
        {viewData.supportAgentText && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "5px",
              border: "2px solid",
              color: "primary.main",
              verticalAlign: "middle",
              backgroundColor: "primary.main",
            }}
            px={1}
            mx={1}
          >
            <SupportAgent sx={{ color: "primary.contrastText" }} />
            <Typography sx={{ color: "primary.contrastText" }}>
              {viewData.supportAgentText}
            </Typography>
          </Box>
        )}
        {viewData.isSelectable && (
          <ArrowForwardIos
            fontSize={"small"}
            style={{ color: "secondary.light" }}
          />
        )}
      </ListItemButton>
      <Divider />
    </Fragment>
  )
}

export default AuthAccountSetupPage
