import { useState } from "react"
import { Navigate, useParams } from "react-router-dom"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import useProjectMemberListPreview from "model/projects/useProjectMemberListPreview"
import UserListSectionView from "components/views/users/list/UserListSectionView"
import paths from "model/utils/paths"
import EditButton from "components/views/generic/EditButton"

const ProjectMemberListPreviewPage = ({ teamKey }: { teamKey: string }) => {
  const [navigateToEditInfo, setNavigateToEditInfo] = useState<boolean>(false)
  const { projectKey } = useParams()
  const [viewData] = useProjectMemberListPreview({
    teamKey,
    projectKey,
  })
  if (navigateToEditInfo && projectKey) {
    return <Navigate to={paths.projectMembersEdit(teamKey, projectKey)} />
  }
  const sections = viewData?.sections?.asJsReadonlyArrayView() || []
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        <>
          {viewData?.editButtonText && (
            <EditButton
              text={viewData?.editButtonText}
              variant="outlined"
              onClick={() => {
                setNavigateToEditInfo(true)
              }}
            />
          )}
        </>
      }
    >
      {sections.map((section) => {
        return (
          <UserListSectionView
            key={section.title}
            teamKey={teamKey}
            section={section}
          />
        )
      })}
    </NavigationBarPageLayout>
  )
}

export default ProjectMemberListPreviewPage
