import React from "react"
import { styled } from "@mui/material/styles"
import { TextField } from "@mui/material"
import { Navigate } from "react-router-dom"

import PageHeader from "components/views/generic/PageHeader"
import AuthPageLayout from "components/views/layouts/AuthPageLayout"
import LoadingButton from "components/views/generic/LoadingButton"
import useAuthSecureCode from "model/auth/useAuthSecureCode"
import paths from "model/utils/paths"

const RootDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
}))

const OuterContainerDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(1),
}))

const SecureCodeTextField = styled(TextField)(({ theme }) => ({
  width: "165px",
  margin: theme.spacing(2),
}))

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
}))

const AuthSecureCodePage = () => {
  const [
    navigateBackToPhoneNumberEntry,
    navigateToAccountSetup,
    viewData,
    input,
  ] = useAuthSecureCode()
  if (navigateBackToPhoneNumberEntry) {
    return <Navigate to={paths.authPhone()} replace={true} />
  }
  if (navigateToAccountSetup) {
    return <Navigate to={paths.authSetup()} replace={true} />
  }
  return (
    <AuthPageLayout title={viewData?.title}>
      {viewData && (
        <RootDiv>
          <PageHeader title={viewData?.title} subtitle={viewData?.subtitle} />
          <OuterContainerDiv>
            <SecureCodeTextField
              label={viewData?.secureCode.label}
              defaultValue={viewData?.secureCode.text}
              error={viewData?.secureCode.error != null}
              helperText={viewData?.secureCode.error}
              autoFocus={true}
              variant={"outlined"}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                input?.changeSecureCode(event.target.value)
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  input?.verify()
                }
              }}
            />
            <div>
              <StyledLoadingButton
                isLoading={viewData.isSendingCode === true}
                title={viewData?.resendSMSButtonText}
                onClick={() => input?.resend()}
                disabled={viewData.isVerifyingSecureCode}
              />
              <StyledLoadingButton
                variant={"contained"}
                isLoading={viewData.isVerifyingSecureCode === true}
                title={viewData?.verifyDeviceButtonText}
                onClick={() => input?.verify()}
                disabled={viewData.isSendingCode}
              />
            </div>
          </OuterContainerDiv>
        </RootDiv>
      )}
    </AuthPageLayout>
  )
}

export default AuthSecureCodePage
