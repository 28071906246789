import { Box, List, Paper, styled, Typography } from "@mui/material"

import * as shared from "probuild-shared"

import UserListItemView from "./UserListItemView"

const StyledTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
}))

const StyledContentBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(4),
}))

const StyledDescription = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(2),
  opacity: 0.7,
  fontStyle: "italic",
}))

const UserListSectionView = ({
  teamKey,
  section,
  onUserDelete,
  onUserSelected,
}: {
  teamKey: string
  section: shared.com.probuildsoftware.probuild.library.users.data.view.UserListSectionViewData
  onUserDelete?: (userKey: string) => void
  onUserSelected?: (userKey: string) => void
}) => {
  const users = section.users?.asJsReadonlyArrayView()
  return (
    <>
      <StyledTitle>{section.title}</StyledTitle>
      <StyledContentBox>
        <List component={Paper} disablePadding={true}>
          {users.map((userItem) => (
            <UserListItemView
              teamKey={teamKey}
              item={userItem}
              onUserDelete={onUserDelete}
              onUserSelected={onUserSelected}
            />
          ))}
        </List>
        {section.sectionDescription && (
          <StyledDescription>{section.sectionDescription}</StyledDescription>
        )}
      </StyledContentBox>
    </>
  )
}

export default UserListSectionView
