import { Box, Typography } from "@mui/material"

import * as shared from "probuild-shared"

import LabeledCard from "components/views/generic/LabeledCard"
import LoadingModal from "components/views/generic/LoadingModal"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import LabeledSwitchView from "components/views/generic/LabeledSwitchView"
import ViewDataToggleButtonGroup from "components/views/generic/ViewDataToggleButtonGroup"
import usePromptSafe from "model/navigation/usePromptSafe"
import { NavigateSafeState } from "model/navigation/useNavigateSafe"

const ContractEditView = ({
  viewData,
  input,
  navigateSafeState,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.contracts.data.view.ContractViewData | null
  input: shared.com.probuildsoftware.probuild.library.contracts.ContractInput | null
  navigateSafeState: NavigateSafeState
}) => {
  usePromptSafe({
    when: viewData?.status.isModified === true,
    message: viewData?.actions.discardConfirmationDialog.message || "",
    navigateSafeState: navigateSafeState,
  })
  if (!input || !viewData) return null
  return (
    <div>
      {viewData.defaultSwitch && (
        <Box mx={2}>
          <LabeledSwitchView
            labelText={viewData.defaultSwitch.label}
            isOn={viewData.defaultSwitch.value}
            onChange={(isOn) => {
              input.changeIsDefault(isOn)
            }}
            fullWidth
          />
        </Box>
      )}
      {viewData.defaultOptions && (
        <Box mx={2} mt={1}>
          <Typography sx={{ textAlign: "center" }}>
            {viewData.defaultOptions.text}
          </Typography>
          <Box sx={{ textAlign: "center" }} my={2}>
            <ViewDataToggleButtonGroup
              viewData={viewData.defaultOptions.options}
              onChange={(identifier: string) => {
                input.changeDefaultOption(identifier)
              }}
            />
          </Box>
        </Box>
      )}
      <LabeledCard text={null}>
        <ViewDataTextField
          viewData={viewData.name}
          onChange={(name) => input?.changeName(name)}
        />
        <ViewDataTextField
          viewData={viewData.text}
          onChange={(text) => input?.changeText(text)}
        />
      </LabeledCard>
      <LoadingModal
        open={
          viewData.status.isSaveInProgress || viewData.status.isDeleteInProgress
        }
      />
    </div>
  )
}

export default ContractEditView
