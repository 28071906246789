import { styled } from "@mui/material/styles"
import { FormControl, FormControlLabel, FormGroup, Switch } from "@mui/material"

const StyledFormControlLabel = styled(FormControlLabel)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: 0,
})

const LabeledSwitchView = ({
  labelText,
  isOn,
  onChange,
  fullWidth,
}: {
  labelText: string | null
  isOn: boolean
  onChange: (newValue: boolean) => void
  fullWidth?: boolean
}) => {
  return (
    <FormControl component="fieldset" variant="standard" fullWidth={fullWidth}>
      <FormGroup>
        <StyledFormControlLabel
          labelPlacement="start"
          label={labelText}
          control={
            <Switch
              size="small"
              checked={isOn}
              onChange={() => {
                onChange(!isOn)
              }}
            />
          }
        />
      </FormGroup>
    </FormControl>
  )
}

export default LabeledSwitchView
