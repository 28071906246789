import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import FeedItemView from "components/views/feed/FeedItemView"
import FeedFilter from "components/views/feed/FeedFilter"
import useFeedListView from "model/feed/useFeedListView"

const FeedPage = ({ teamKey }: { teamKey: string }) => {
  const feedListView = useFeedListView({ teamKey })
  return (
    <NavigationBarPageLayout
      loadMoreAtBottom={feedListView.loadMoreAtBottom}
      hasMore={true}
      showLoadingSpinner={!feedListView.feedList}
    >
      <FeedFilter
        filter={feedListView.typeFilter}
        filterChanged={feedListView.changeTypeFilter}
      />
      {feedListView.feedList?.items.map((feedItem) => {
        return (
          <FeedItemView
            key={feedItem.key}
            item={feedItem}
            showTeamName={false}
          />
        )
      })}
    </NavigationBarPageLayout>
  )
}

export default FeedPage
