import { Box, List, Paper } from "@mui/material"
import { useCallback } from "react"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import ProjectListItemView from "components/views/projects/list/ProjectListItemView"
import LoadingView from "components/views/LoadingView"
import useProjectList from "model/projects/useProjectList"
import EmptyView from "components/views/EmptyView"
import Dashboard from "@mui/icons-material/Dashboard"
import paths from "model/utils/paths"

const ProjectListPage = ({ teamKey }: { teamKey: string }) => {
  const [viewData, input] = useProjectList({ teamKey })
  const loadMoreAtBottom = useCallback(() => {
    input?.loadMoreAtBottom()
  }, [input])
  if (!viewData || !input) return <></>
  const projectListItems = viewData?.items?.asJsReadonlyArrayView() || []
  const hasMore = viewData?.hasMoreAtBottom === true
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      searchPlaceholder={viewData?.searchPlaceholder || ""}
      searchOnChange={(text) => input?.changeSearchQuery(text)}
      createButtons={[
        {
          to: paths.projectCreate(teamKey),
          text: viewData?.createButtonText,
        },
      ]}
      loadMoreAtBottom={loadMoreAtBottom}
      hasMore={hasMore}
      showLoadingSpinner={!viewData}
    >
      <List component={Paper} disablePadding={true}>
        {projectListItems.map((item) => {
          return (
            <ProjectListItemView teamKey={teamKey} projectListItem={item} />
          )
        })}
        {viewData.empty && (
          <Box sx={{ p: 2 }}>
            <EmptyView icon={Dashboard} viewData={viewData.empty} />
          </Box>
        )}
      </List>
      {viewData.isLoadingAtBottom === true && <LoadingView key={0} />}
    </NavigationBarPageLayout>
  )
}

export default ProjectListPage
