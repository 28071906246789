import React, { ReactNode, useState } from "react"
import { styled } from "@mui/material/styles"
import {
  TextField,
  Select,
  MenuItem,
  ListSubheader,
  Button,
  SelectChangeEvent,
  Typography,
} from "@mui/material"
import { Navigate } from "react-router-dom"

import * as shared from "probuild-shared"

import PageHeader from "components/views/generic/PageHeader"
import AuthPageLayout from "components/views/layouts/AuthPageLayout"
import LoadingButton from "components/views/generic/LoadingButton"
import useAuthPhoneNumber from "model/auth/useAuthPhoneNumber"
import paths from "model/utils/paths"
import InstallSuggestionView from "components/views/generic/InstallSuggestionView"
import LinkToExternalSite from "components/views/generic/LinkToExternalSite"
import usePlatform from "model/utils/usePlatform"

const ContentDiv = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
}))

const SupportAgentButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: "100%",
}))

const Fields = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}))

const CenteringContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: theme.spacing(3),
}))

const CenteredContent = styled("div")({
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "flex-end",
})

const DisclaimerDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
  textAlign: "center",
}))

const StyledTypography = styled(Typography)({
  fontStyle: "italic",
  fontSize: "small",
})

const StyledLinkToExternalSite = styled(LinkToExternalSite)(({ theme }) => ({
  color: theme.palette.primary.main,
  [`&:hover`]: {
    textDecoration: "underline",
  },
}))

const AuthPhonePage = () => {
  const [navigateToSecureCodeEntry, viewData, input] = useAuthPhoneNumber()
  const [showInstallSuggestion, setShowInstallSuggestion] = useState(true)
  const platform = usePlatform()
  if (showInstallSuggestion && (platform === "iOS" || platform === "Android")) {
    return (
      <InstallSuggestionView
        ignoreSuggestion={() => setShowInstallSuggestion(false)}
      />
    )
  }
  if (!viewData || !viewData.countryCode) {
    return null
  }
  if (navigateToSecureCodeEntry) {
    return <Navigate to={paths.authSecureCode()} />
  }
  const updateSupportAgentPhoneNumber = () => {
    const newSupportAgentPhoneNumber = prompt(
      viewData.supportAgentPhoneNumberLabel || "",
      viewData.supportAgentPhoneNumber || ""
    )
    if (newSupportAgentPhoneNumber != null) {
      input?.changeSupportAgentPhoneNumber(newSupportAgentPhoneNumber)
    }
  }
  return (
    <AuthPageLayout
      title={viewData?.title}
      onLogoLongPress={updateSupportAgentPhoneNumber}
    >
      {viewData && (
        <ContentDiv>
          <PageHeader title={viewData?.title} subtitle={viewData.subtitle} />
          {viewData?.supportAgentPhoneNumber !== null && (
            <SupportAgentButton
              variant={"contained"}
              onClick={updateSupportAgentPhoneNumber}
            >
              {viewData?.supportAgentPhoneNumberLabel}:{" "}
              {viewData.supportAgentPhoneNumber}
            </SupportAgentButton>
          )}
          <CenteringContainer>
            <CenteredContent>
              <Fields>
                <CountryCodeSelect input={input} viewData={viewData} />
                <TextField
                  autoFocus
                  label={viewData.phoneNumber.label}
                  defaultValue={viewData.phoneNumber.text}
                  disabled={viewData.isSendingCode}
                  helperText={viewData.phoneNumber.error}
                  error={viewData.phoneNumber.error != null}
                  variant={"outlined"}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    input?.changePhoneNumber(event.target.value)
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      input?.next()
                    }
                  }}
                />
              </Fields>
              <LoadingButton
                variant={"contained"}
                isLoading={viewData.isSendingCode === true}
                title={viewData.nextButtonText}
                onClick={() => input?.next()}
              />
            </CenteredContent>
            <DisclaimerDiv>
              <StyledTypography>
                {viewData.byProceedingLabel}{" "}
                <StyledLinkToExternalSite
                  path={viewData.termsOfUseLinkUrl}
                  title={viewData.termsOfUseLinkText}
                />{" "}
                {viewData.andLabel}{" "}
                <StyledLinkToExternalSite
                  path={viewData.privacyPolicyLinkUrl}
                  title={viewData.privacyPolicyLinkText}
                />
                .
              </StyledTypography>
            </DisclaimerDiv>
          </CenteringContainer>
        </ContentDiv>
      )}
    </AuthPageLayout>
  )
}

const CountryCodeSelect = ({
  viewData,
  input,
}: {
  viewData: shared.com.probuildsoftware.probuild.library.auth.data.view.AuthPhoneNumberViewData
  input: shared.com.probuildsoftware.probuild.library.auth.AuthPhoneNumberInput | null
}) => {
  const groups = viewData.countries.asJsReadonlyArrayView()
  const countriesList: React.ReactElement[] = []
  groups.forEach((group) => {
    countriesList.push(
      <ListSubheader key={group.title} disableSticky={true}>
        {group.title}
      </ListSubheader>
    )
    const countries = group.countries.asJsReadonlyArrayView()
    countries.forEach((country) => {
      countriesList.push(
        <MenuItem key={country.name} value={country.countryCode}>
          {country.name} +{country.dialCode}
        </MenuItem>
      )
    })
  })

  return (
    <Select
      value={viewData.countryCode}
      disabled={viewData.isSendingCode}
      variant="standard"
      disableUnderline={true}
      onChange={(event: SelectChangeEvent<unknown>, child: ReactNode) => {
        if (event.target.value) {
          input?.changeCountryCode(event.target.value as string)
        }
      }}
      renderValue={() => viewData.dialCode}
    >
      {countriesList}
    </Select>
  )
}

export default AuthPhonePage
