import { useCallback, useState } from "react"
import { styled } from "@mui/material/styles"
import { Button, Typography, Box, ButtonBase } from "@mui/material"
import ContactsIcon from "@mui/icons-material/Contacts"

import * as shared from "probuild-shared"

import ClientListDialog from "components/dialogs/ClientListDialog"
import ClientEditDialog from "components/dialogs/ClientEditDialog"

const ClientButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))

const CurrentClientButtonContentDiv = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const CurrentClientNameBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  gap: theme.spacing(1),
}))

const ClientDetailBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}))

const Client = ({
  teamKey,
  documentAnswerKey,
  element,
  input,
}: {
  teamKey: string
  documentAnswerKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
}) => {
  const {
    clientName,
    clientKey,
    detailText,
    changeButtonText,
    removeButtonText,
  } = element.client || {}

  const [isSelectingClient, setIsSelectingClient] = useState<boolean>(false)
  const [isEditingClient, setIsEditingClient] = useState<boolean>(false)
  const [isCreatingClient, setIsCreatingClient] = useState<boolean>(false)
  const onAddClientClicked = () => {
    setIsSelectingClient(true)
  }
  const onSave = useCallback(
    (clientKey: string) => {
      input.changeClient(documentAnswerKey, clientKey)
    },
    [documentAnswerKey, input]
  )
  const onCloseClientDialog = useCallback(() => {
    setIsEditingClient(false)
    setIsCreatingClient(false)
  }, [])
  const onRemoveButtonClicked = () =>
    input.changeClient(documentAnswerKey, null)
  const onClientSelected = (clientKey: string | null) => {
    setIsSelectingClient(false)
    if (clientKey) {
      input.changeClient(documentAnswerKey, clientKey)
    }
  }
  const onCreateClientClicked = () => {
    setIsCreatingClient(true)
    setIsSelectingClient(false)
  }
  return (
    <>
      {clientKey ? (
        <ClientButton
          onClick={() => {
            setIsEditingClient(true)
          }}
          sx={{ pb: changeButtonText ? 1 : 2 }}
        >
          <CurrentClientButtonContentDiv>
            <CurrentClientNameBox>
              <Typography fontWeight="bold">{clientName}</Typography>
              {detailText && (
                <Typography fontStyle="italic">{detailText}</Typography>
              )}
            </CurrentClientNameBox>
            <ContactsIcon color="primary" />
          </CurrentClientButtonContentDiv>
        </ClientButton>
      ) : (
        detailText && (
          <ClientDetailBox>
            <Typography fontStyle="italic">{detailText}</Typography>
          </ClientDetailBox>
        )
      )}
      {changeButtonText && (
        <Button
          sx={{ ml: 2, mb: 2, mt: clientName ? 1 : 2 }}
          variant="contained"
          color="primary"
          onClick={onAddClientClicked}
        >
          {changeButtonText}
        </Button>
      )}
      {removeButtonText && (
        <Button
          sx={{ ml: 1, mb: 2, mt: clientName ? 1 : 2 }}
          color="primary"
          onClick={onRemoveButtonClicked}
        >
          {removeButtonText}
        </Button>
      )}
      {isSelectingClient && (
        <ClientListDialog
          teamKey={teamKey}
          isOpen={isSelectingClient}
          onClientSelected={onClientSelected}
          onCreateClicked={onCreateClientClicked}
        />
      )}
      {(isEditingClient || isCreatingClient) && (
        <ClientEditDialog
          teamKey={teamKey}
          clientKey={!isCreatingClient && clientKey ? clientKey : undefined}
          isOpen={isEditingClient || isCreatingClient}
          onCloseDialog={onCloseClientDialog}
          onSave={onSave}
        />
      )}
    </>
  )
}

export default Client
