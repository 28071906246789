import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import * as shared from "probuild-shared"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import ClientEditView from "components/views/clients/edit/ClientEditView"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import SaveButton from "components/views/generic/SaveButton"
import useClient from "model/clients/edit/useClient"
import useSnackbar from "model/snackbar/useSnackbar"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import DeleteActionButton from "components/views/generic/DeleteActionButton"

const ClientEditPage = ({ teamKey }: { teamKey: string }) => {
  const { clientKey } = useParams()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const showSnackbar = useSnackbar()
  const observer: shared.com.probuildsoftware.probuild.library.clients.ClientEventObserver =
    useMemo(() => {
      return {
        onNavigateBack() {
          navigate(-1)
        },

        onShowSuccessSnackbar(message: string) {
          showSnackbar(message, "success")
        },
        onShowErrorSnackbar(message: string) {
          showSnackbar(message, "error")
        },
        onSave(
          clientKey: string,
          address: string | null,
          lat: number | null,
          lng: number | null
        ) {},
      }
    }, [showSnackbar, navigate])
  const [viewData, input] = useClient({ teamKey, clientKey, observer })
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const saveChanges = () => {
    input?.save()
  }
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true)
  }
  const deleteConfirmed = () => {
    setDeleteDialogOpen(false)
    input?.delete()
  }
  const hideDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData?.actions.saveButtonText}
          onClick={saveChanges}
          isEnabled={viewData?.status.isModified}
        />
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <ClientEditView
            viewData={viewData}
            input={input}
            navigateSafeState={navigateSafeState}
          />
          <ConfirmationDialog
            open={deleteDialogOpen}
            onConfirm={deleteConfirmed}
            onCancel={hideDeleteDialog}
            dialogViewData={viewData.actions.deleteConfirmationDialog}
          />
          <DeleteActionButton
            text={viewData?.actions.deleteButtonText}
            onClick={openDeleteDialog}
          />
        </>
      )}
    </NavigationBarPageLayout>
  )
}

export default ClientEditPage
