import shared from "probuild-shared"

class LiveLegacyAuthApi
  implements
    shared.com.probuildsoftware.probuild.library.common.model.auth.api
      .LegacyAuthApi
{
  private authClaims: { [key: string]: any } | undefined

  constructor(authClaims: { [key: string]: any } | undefined) {
    this.authClaims = authClaims
  }

  observe(
    onChange: (
      legacyAuthData: shared.com.probuildsoftware.probuild.library.common.model.auth.api.LegacyAuthData
    ) => void
  ): () => void {
    onChange(this.createAuthData())
    return () => {}
  }

  private createAuthData() {
    if (this.authClaims) {
      const { isSupportAgent, users, readOnlyUsers, deviceKey } =
        this.authClaims
      const isImpersonating = Object.keys(readOnlyUsers || {}).length > 0
      const accounts =
        shared.com.probuildsoftware.probuild.library.common.utils.ArrayUtils.Companion.convertToKotlinList(
          this.createAccounts(users).concat(this.createAccounts(readOnlyUsers))
        )
      return new shared.com.probuildsoftware.probuild.library.common.model.auth.api.LegacyAuthData(
        isSupportAgent === true,
        isImpersonating,
        deviceKey,
        accounts
      )
    } else {
      return new shared.com.probuildsoftware.probuild.library.common.model.auth.api.LegacyAuthData(
        false,
        false,
        null,
        shared.com.probuildsoftware.probuild.library.common.utils.ArrayUtils.Companion.convertToKotlinList(
          this.createAccounts(undefined)
        )
      )
    }
  }

  private createAccounts(
    users: any | undefined
  ): shared.com.probuildsoftware.probuild.library.common.model.auth.api.LegacyAuthAccountData[] {
    if (!users) return []
    return Object.entries(users).map(this.createAccount)
  }

  private createAccount(entry: [string, unknown]) {
    const [teamKey, userKey] = entry
    return new shared.com.probuildsoftware.probuild.library.common.model.auth.api.LegacyAuthAccountData(
      teamKey,
      userKey as string
    )
  }
}

export default LiveLegacyAuthApi
