import { styled } from "@mui/material/styles"
import {
  Box,
  Button,
  Checkbox,
  Divider,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material"
import { Delete } from "@mui/icons-material"

import * as shared from "probuild-shared"

import paths from "model/utils/paths"
import UserAvatar from "components/views/users/UserAvatar"
import { Link } from "react-router-dom"
import { ReactNode } from "react"

const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(1),
}))

const TextContainerDiv = styled("div")(({ theme }) => ({
  paddingLeft: theme.spacing(2),
}))

const StyledContentDiv = styled("div")({
  display: "flex",
  alignItems: "center",
})

const UserListItemView = ({
  teamKey,
  item,
  onUserDelete,
  onUserSelected,
}: {
  teamKey: string
  item: shared.com.probuildsoftware.probuild.library.users.data.view.UserListItemViewData
  onUserDelete?: (userKey: string) => void
  onUserSelected?: (userKey: string) => void
}) => {
  const userPath = paths.userEdit(teamKey, item.userKey)
  const ListItemParent: React.FC<{ children: ReactNode }> = ({ children }) => {
    if (!item.isEnabled || onUserDelete) {
      return <ListItem>{children}</ListItem>
    } else if (onUserSelected) {
      return (
        <ListItemButton onClick={() => onUserSelected(item.userKey)}>
          {children}
        </ListItemButton>
      )
    } else {
      return (
        <ListItemButton component={Link} to={userPath}>
          {children}
        </ListItemButton>
      )
    }
  }
  return (
    <>
      <ListItemParent>
        <StyledBox sx={{ opacity: item.isEnabled ? 1 : 0.7 }}>
          <StyledContentDiv>
            <UserAvatar size={50} viewData={item.avatar} />
            <TextContainerDiv>
              <Box fontStyle={item.isNameItalicized ? "italic" : "normal"}>
                {item.name}
              </Box>
              <Typography color="textSecondary">{item.phoneNumber}</Typography>
            </TextContainerDiv>
          </StyledContentDiv>
          {item.isMultiSelect && (
            <Checkbox
              color="primary"
              checked={item.isSelected}
              disabled={!item.isEnabled}
            />
          )}
        </StyledBox>
        {item.deleteButtonText && onUserDelete && (
          <Button
            onClick={() => onUserDelete(item.userKey)}
            variant="outlined"
            color="error"
            startIcon={<Delete />}
          >
            {item.deleteButtonText}
          </Button>
        )}
      </ListItemParent>
      <Divider />
    </>
  )
}

export default UserListItemView
