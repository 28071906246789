import { useCallback, useMemo, useState } from "react"

import * as shared from "probuild-shared"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import useSnackbar from "model/snackbar/useSnackbar"
import { useParams } from "react-router-dom"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import usePostPreview from "model/projects/usePostPreview"
import PostListItemView from "components/views/projects/feed/PostListItemView"
import PostListItemGalleryView from "components/views/projects/feed/attachments/PostListItemGalleryView"
import PostEditDialog from "components/dialogs/PostEditDialog"
import EditButton from "components/views/generic/EditButton"
import PostCommentsListView from "components/views/projects/feed/PostCommentsListView"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"

const PostPreviewPage = ({ teamKey }: { teamKey: string }) => {
  const { projectKey, postKey } = useParams()
  const showSnackbar = useSnackbar()
  const [navigate] = useNavigateSafe()
  const [isEditingPost, setIsEditingPost] = useState<boolean>(false)
  const [gallerySelectedAttachmentKey, setGallerySelectedAttachmentKey] =
    useState<string | null>(null)
  const observer = useMemo(() => {
    return {
      onShowSuccessSnackbar(message: string) {
        showSnackbar(message, "success")
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateBack(errorMessage?: string) {
        if (errorMessage) {
          showSnackbar(errorMessage, "error")
        }
        navigate(-1)
      },
      onNavigateToPostEdit() {
        setIsEditingPost(true)
      },
      onSendEmailReady(
        emailSubject: string,
        emailBody: string,
        paths: shared.kotlin.collections.KtList<string>
      ) {},
      onEditComment(commentKey: string, commentText: string) {},
      onViewPhotoComment(commentKey: string) {},
      onDownloadedFileReady(
        event: shared.com.probuildsoftware.probuild.library.projects.data.event.DownloadedFileEvent
      ) {},
      onSharePhotosReady(paths: shared.kotlin.collections.KtList<string>) {},
      onShareFilesReady(paths: shared.kotlin.collections.KtList<string>) {},
    }
  }, [navigate, showSnackbar])
  const [viewData, input] = usePostPreview({
    teamKey,
    projectKey,
    postKey,
    observer,
  })
  const onPhotoClicked = useCallback(
    (
      attachmentKey: string,
      postListItemViewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostListItemViewData
    ) => {
      setGallerySelectedAttachmentKey(attachmentKey)
    },
    []
  )
  const galleryPostViewData =
    (gallerySelectedAttachmentKey && viewData?.listItemViewData) || null
  const onPhotoViewed = useCallback(
    (postKey: string, attachmentKey: string) => {
      input?.ensureAttachmentDownloaded(attachmentKey)
    },
    [input]
  )
  const onPostMenuActionSelected = useCallback(
    (postKey: string, actionKey: string) => {
      input?.onPostOverflowMenuAction(actionKey)
    },
    [input]
  )
  const onBecameVisible = useCallback(() => {}, [])
  const onCommentButtonClicked = useCallback(() => {}, [])
  if (!viewData || !input || !projectKey) return <></>
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        viewData.editButtonText && (
          <EditButton
            text={viewData.editButtonText}
            variant="outlined"
            onClick={() => {
              setIsEditingPost(true)
            }}
          />
        )
      }
    >
      <ConfirmationDialog
        open={viewData?.deleteDialog != null}
        onConfirm={() => input?.deleteConfirmed()}
        onCancel={() => input?.deleteCancelled()}
        dialogViewData={viewData?.deleteDialog}
      />
      <PostListItemView
        teamKey={teamKey}
        onCommentButtonClicked={onCommentButtonClicked}
        viewData={viewData.listItemViewData}
        projectKey={projectKey}
        onPostMenuActionSelected={onPostMenuActionSelected}
        onPhotoClicked={onPhotoClicked}
        onPhotoViewed={onPhotoViewed}
        onBecameVisible={onBecameVisible}
        isCommentCountButtonDisabled={true}
        isAllPhotoAttachmentsVisible={true}
      >
        <PostCommentsListView
          teamKey={teamKey}
          viewData={viewData}
          input={input}
        />
      </PostListItemView>

      <PostListItemGalleryView
        initialAttachmentKey={gallerySelectedAttachmentKey}
        viewData={galleryPostViewData}
        onPhotoViewed={onPhotoViewed}
        onClose={() => setGallerySelectedAttachmentKey(null)}
      />
      {isEditingPost && (
        <PostEditDialog
          teamKey={teamKey}
          projectKey={projectKey}
          postKey={postKey}
          isOpen={isEditingPost}
          actionKeyOnFirstLoad={null}
          onCloseDialog={() => {
            setIsEditingPost(false)
          }}
        />
      )}
    </NavigationBarPageLayout>
  )
}

export default PostPreviewPage
