import { useContext } from "react"

import UserContext from "contexts/UserContext"

function useDeviceKey(): string | null {
  const userState = useContext(UserContext)
  return userState.signedInUser?.claims.deviceKey || null
}

export default useDeviceKey
