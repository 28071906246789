import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import { Button, Paper, Typography } from "@mui/material"
import { GetApp } from "@mui/icons-material"
import { useMemo, useState } from "react"
import { styled } from "@mui/material/styles"

import * as shared from "probuild-shared"

import useDownloadFile from "model/utils/useDownloadFile"
import useTimesheetUserList from "model/timesheets/useTimesheetsUserList"
import TimesheetListItemView from "components/views/timesheets/TimesheetListItemView"
import TimesheetUserDialog from "components/dialogs/TimesheetUserDialog"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { grey } from "@mui/material/colors"
import LoadingModal from "components/views/generic/LoadingModal"

const CurrentPayPeriodDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
}))

const PayPeriodSelectorDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  textAlign: "center",
}))

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
}))

const TotalPayPeriodDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  background: grey[300],
}))

const TimesheetUserListPage = ({ teamKey }: { teamKey: string }) => {
  const [timesheetUserKeyDialog, setTimesheetUserKeyDialog] = useState<
    string | null
  >(null)
  const downloadFile = useDownloadFile()
  const timesheetObserver: shared.com.probuildsoftware.probuild.library.timesheets.TimesheetUserListEventObserver =
    useMemo(() => {
      return {
        onExportCsvReady(filename: string, csvContent: string) {
          downloadFile(filename, csvContent, "text/csv")
        },
      }
    }, [downloadFile])
  const [timesheetViewData, input] = useTimesheetUserList({
    teamKey,
    projectKey: null,
    observer: timesheetObserver,
  })
  const userListItems = timesheetViewData?.items?.asJsReadonlyArrayView()
  if (!timesheetViewData) return null
  return (
    <NavigationBarPageLayout
      title={timesheetViewData?.title}
      showLoadingSpinner={!timesheetViewData}
    >
      {timesheetViewData.exportAllButtonText && (
        <Button
          startIcon={<GetApp />}
          onClick={() => {
            input?.exportCsv()
          }}
          sx={{ mb: 2 }}
        >
          {timesheetViewData.exportAllButtonText}
        </Button>
      )}

      <Paper>
        <CurrentPayPeriodDiv>
          <StyledTypography>CURRENT PERIOD</StyledTypography>
          <PayPeriodSelectorDiv>
            <Button startIcon={<KeyboardArrowLeftIcon />} onClick={() => {}} />
            <Typography>CURRENT PERIOD VALUE</Typography>
            <Button startIcon={<KeyboardArrowRightIcon />} onClick={() => {}} />
          </PayPeriodSelectorDiv>
        </CurrentPayPeriodDiv>
        <TotalPayPeriodDiv>
          <StyledTypography>Pay Period Total</StyledTypography>
          <StyledTypography>Xh XXm</StyledTypography>
        </TotalPayPeriodDiv>
        {userListItems?.map((listItem) => {
          return (
            <TimesheetListItemView
              teamKey={teamKey}
              onUserClicked={(userKey) => setTimesheetUserKeyDialog(userKey)}
              item={listItem}
            />
          )
        })}
      </Paper>

      {timesheetUserKeyDialog && (
        <TimesheetUserDialog
          teamKey={teamKey}
          projectKey={""}
          userKey={timesheetUserKeyDialog}
          isOpen={timesheetUserKeyDialog != null}
          onClose={() => {
            setTimesheetUserKeyDialog(null)
          }}
        />
      )}
      <LoadingModal open={timesheetViewData.isPreparingExport} />
    </NavigationBarPageLayout>
  )
}

export default TimesheetUserListPage
