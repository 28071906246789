import { useMemo } from "react"
import { Divider } from "@mui/material"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import useProjectEdit from "model/projects/useProjectEdit"
import useSnackbar from "model/snackbar/useSnackbar"
import LabeledCard from "components/views/generic/LabeledCard"
import ViewDataTextField from "components/views/generic/ViewDataTextField"
import AddressAndMapView from "components/views/generic/AddressAndMapView"
import SaveButton from "components/views/generic/SaveButton"
import ClientSummaryView from "components/views/clients/summary/ClientSummaryView"
import DropdownView from "components/views/generic/DropdownView"
import { useParams } from "react-router-dom"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import usePromptSafe from "model/navigation/usePromptSafe"
import paths from "model/utils/paths"
import LoadingModal from "components/views/generic/LoadingModal"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import DeleteActionButton from "components/views/generic/DeleteActionButton"

const ProjectEditPage = ({ teamKey }: { teamKey: string }) => {
  const { projectKey } = useParams()
  const showSnackbar = useSnackbar()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const observer = useMemo(() => {
    return {
      onShowSuccessSnackbar(message: string) {
        showSnackbar(message, "success")
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateToMemberList(projectKey: string) {
        navigate(paths.projectMembersEdit(teamKey, projectKey), {
          replace: true,
          state: { isNewProject: true },
        })
      },
      onNavigateBack() {
        navigate(-1)
      },
    }
  }, [navigate, showSnackbar, teamKey])
  const [viewData, input] = useProjectEdit({ teamKey, projectKey, observer })
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog.message,
    navigateSafeState: navigateSafeState,
  })
  if (!viewData || !input) return <></>
  const onClientChanged = (clientKey: string | null) => {
    if (clientKey) {
      input.changeClient(clientKey)
    } else {
      input.removeClient()
    }
  }
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData.saveButtonText}
          isEnabled={true}
          onClick={() => input?.save()}
        />
      }
    >
      <LabeledCard text={""}>
        <ViewDataTextField
          viewData={viewData.name}
          onChange={(text) => input?.changeName(text)}
        />
        <ViewDataTextField
          viewData={viewData.description}
          onChange={(text) => input?.changeDescription(text)}
        />
        <DropdownView
          viewData={viewData.status}
          onChange={(statusKey) => {
            input?.changeStatus(statusKey)
          }}
        />
        <Divider />
        <ClientSummaryView
          teamKey={teamKey}
          client={viewData.client}
          onClientChanged={onClientChanged}
        />
        <Divider />
        <AddressAndMapView
          location={viewData.location}
          onChange={(address, lat, lng) => {
            input?.changeLocation(address, lat, lng)
          }}
        />
      </LabeledCard>
      <DeleteActionButton
        text={viewData.deleteButtonText}
        onClick={() => input?.deleteInitiated()}
      />
      <ConfirmationDialog
        open={viewData?.deleteProjectDialog != null}
        onConfirm={() => input?.deleteConfirmed()}
        onCancel={() => input?.deleteCancelled()}
        dialogViewData={viewData?.deleteProjectDialog}
      />
      <LoadingModal
        open={viewData.isSaveInProgress || viewData.isDeleteInProgress}
      />
    </NavigationBarPageLayout>
  )
}

export default ProjectEditPage
