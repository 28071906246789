import React, { useState, useEffect } from "react"
import { styled } from "@mui/material/styles"
import { Typography, Hidden } from "@mui/material"

import useAuthIntentSelectionViewData from "model/auth/useAuthIntentSelectionViewData"
import ProbuildNavigationBar from "components/views/ProbuildNavigationBar"

const RightPaneDiv = styled("div")({
  width: "100%",
  flexGrow: 1,
})

const LeftPaneDiv = styled("div")(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  overflowY: "auto",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(8),
    minWidth: 600,
    maxWidth: 600,
  },
}))

const GridBackgroundDiv = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  backgroundImage: "url('/grid.png')",
  backgroundSize: "200px 200px",
  height: "100%",
  width: "100%",
})

const BrandContainerDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
})

const LogoImage = styled("img")({
  width: 270,
})

const WhiteCenteredTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  textAlign: "center",
}))

const RootDiv = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  backgroundColor: theme.palette.primary.main,
}))

const AuthPageLayout = ({
  title,
  onLogoLongPress,
  children,
}: {
  title: string | null | undefined
  onLogoLongPress?: (() => void) | null
  children: React.ReactNode
}) => {
  const [buttonPressTimer, setButtonPressTimer] = useState<any>()
  useEffect(() => {
    if (title) {
      document.title = `Probuild | ${title}`
    }
  }, [title])
  const onMouseDown = () => {
    setButtonPressTimer(
      setTimeout(() => {
        if (onLogoLongPress) {
          onLogoLongPress()
        }
      }, 10000)
    )
  }
  const onMouseUp = () => {
    clearTimeout(buttonPressTimer)
  }
  const viewData = useAuthIntentSelectionViewData()
  return (
    <RootDiv>
      <LeftPaneDiv>
        <Hidden mdUp>
          <ProbuildNavigationBar />
        </Hidden>
        {children}
      </LeftPaneDiv>
      <Hidden mdDown>
        <RightPaneDiv>
          <GridBackgroundDiv>
            <BrandContainerDiv>
              <LogoImage
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                src="/logoTransparent.png"
                alt="Probuild"
              />
              <WhiteCenteredTypography variant="h2">
                {viewData?.title}
              </WhiteCenteredTypography>
              <WhiteCenteredTypography variant="h5">
                {viewData?.subtitle}
              </WhiteCenteredTypography>
            </BrandContainerDiv>
          </GridBackgroundDiv>
        </RightPaneDiv>
      </Hidden>
    </RootDiv>
  )
}

export default AuthPageLayout
