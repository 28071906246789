import { useCallback, useMemo, useState } from "react"
import DashboardIcon from "@mui/icons-material/Dashboard"
import { Navigate, useParams } from "react-router-dom"

import * as shared from "probuild-shared"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import LoadingView from "components/views/LoadingView"
import EmptyView from "components/views/EmptyView"
import useProjectPreview from "model/projects/useProjectPreview"
import PostListItemView from "components/views/projects/feed/PostListItemView"
import ConfirmationDialog from "components/dialogs/ConfirmationDialog"
import PostListItemGalleryView from "components/views/projects/feed/attachments/PostListItemGalleryView"
import PostEditDialog from "components/dialogs/PostEditDialog"
import ProjectPreviewHeaderView from "components/views/projects/feed/ProjectPreviewHeaderView"
import useSnackbar from "model/snackbar/useSnackbar"
import ClientEditDialog from "components/dialogs/ClientEditDialog"
import TimesheetUserListDialog from "components/dialogs/TimesheetUserListDialog"
import TimesheetUserDialog from "components/dialogs/TimesheetUserDialog"
import paths from "model/utils/paths"
import MainCreateButton from "components/views/generic/MainCreateButton"
import useNavigateSafe from "model/navigation/useNavigateSafe"

export interface PostEditDialogOptions {
  postKey: string | null
  actionKeyOnFirstLoad: string | null
}

const ProjectPage = ({ teamKey }: { teamKey: string }) => {
  const { projectKey } = useParams()
  const showSnackbar = useSnackbar()
  const [navigate] = useNavigateSafe()
  const [postEditDialogOptions, setPostEditDialogOptions] =
    useState<PostEditDialogOptions | null>(null)
  const observer = useMemo(() => {
    return {
      onNavigateBack() {
        navigate(-1)
      },
      onShowErrorSnackbar(message: string) {
        showSnackbar(message, "error")
      },
      onNavigateToPostCreate(actionKeyOnFirstLoad: string) {
        setPostEditDialogOptions({
          postKey: null,
          actionKeyOnFirstLoad: actionKeyOnFirstLoad,
        })
      },
      onNavigateToPostEdit(postKey: string) {
        setPostEditDialogOptions({ postKey, actionKeyOnFirstLoad: null })
      },
      onSendEmailReady(
        emailSubject: string,
        emailBody: string,
        paths: shared.kotlin.collections.KtList<string>
      ) {},
      onDownloadedFileReady(
        event: shared.com.probuildsoftware.probuild.library.projects.data.event.DownloadedFileEvent
      ) {},
      onSharePhotosReady(paths: shared.kotlin.collections.KtList<string>) {},
      onShareFilesReady(paths: shared.kotlin.collections.KtList<string>) {},
    }
  }, [navigate, showSnackbar])
  const [viewData, input] = useProjectPreview({ teamKey, projectKey, observer })
  const posts = useMemo(
    () => viewData?.posts?.asJsReadonlyArrayView() || [],
    [viewData]
  )
  const [galleryPostKey, setGalleryPostKey] = useState<string | null>(null)
  const galleryPostViewData = useMemo(() => {
    return (
      (galleryPostKey &&
        posts.find((post) => {
          return post.postKey === galleryPostKey
        })) ||
      null
    )
  }, [posts, galleryPostKey])
  const [navigateToEditInfo, setNavigateToEditInfo] = useState<boolean>(false)
  const [navigateToMembers, setNavigateToMembers] = useState<boolean>(false)
  const [navigateToPostKey, setNavigateToPostKey] = useState<string | null>(
    null
  )
  const [gallerySelectedAttachmentKey, setGallerySelectedAttachmentKey] =
    useState<string | null>(null)
  const [navigateToClientKey, setNavigateToClientKey] = useState<string | null>(
    null
  )
  const [isTimesheetUserListDialogOpen, setIsTimesheetUserListDialogOpen] =
    useState<boolean>(false)
  const [timesheetUserKeyDialog, setTimesheetUserKeyDialog] = useState<
    string | null
  >(null)
  const onPhotoClicked = useCallback(
    (
      attachmentKey: string,
      postListItemViewData: shared.com.probuildsoftware.probuild.library.projects.data.view.PostListItemViewData
    ) => {
      setGallerySelectedAttachmentKey(attachmentKey)
      setGalleryPostKey(postListItemViewData.postKey)
    },
    []
  )
  const onPhotoViewed = useCallback(
    (postKey: string, attachmentKey: string) => {
      input?.ensureAttachmentDownloaded(postKey, attachmentKey)
    },
    [input]
  )
  const onPostMenuActionSelected = useCallback(
    (postKey: string, actionKey: string) => {
      input?.onPostOverflowMenuAction(postKey, actionKey)
    },
    [input]
  )
  const onBecameVisible = useCallback(
    (postKey: string) => {
      input?.markPostAsRead(postKey)
    },
    [input]
  )
  const onClosePostEditDialog = useCallback(() => {
    setPostEditDialogOptions(null)
  }, [setPostEditDialogOptions])
  const onCommentButtonClicked = useCallback((postKey: string) => {
    setNavigateToPostKey(postKey)
  }, [])
  const onPostCreateActionClicked = useCallback(
    (
      actionViewData: shared.com.probuildsoftware.probuild.library.common.data.view.ActionViewData
    ) => {
      input?.onPostCreateMenuAction(actionViewData.actionKey)
    },
    [input]
  )
  if (navigateToMembers && projectKey) {
    return <Navigate to={paths.projectMembersPreview(teamKey, projectKey)} />
  }
  if (navigateToEditInfo && projectKey) {
    return <Navigate to={paths.projectInfo(teamKey, projectKey)} />
  }
  if (navigateToPostKey && projectKey) {
    return (
      <Navigate
        to={paths.postPreview(teamKey, projectKey, navigateToPostKey)}
      />
    )
  }
  if (!projectKey) return null
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        <>
          {viewData?.mainAction && (
            <MainCreateButton
              text={viewData.mainAction.text}
              menu={viewData.mainAction.actionMenu ?? null}
              onButtonClicked={() => {
                setPostEditDialogOptions({
                  postKey: null,
                  actionKeyOnFirstLoad: null,
                })
              }}
              onMenuActionClicked={onPostCreateActionClicked}
            />
          )}
        </>
      }
      loadMoreAtBottom={() => input?.loadMoreAtBottom()}
      hasMore={viewData?.hasMoreAtBottom === true}
    >
      {!viewData ? (
        <LoadingView />
      ) : (
        <>
          <ProjectPreviewHeaderView
            onClientClicked={(clientKey: string) => {
              setNavigateToClientKey(clientKey)
            }}
            onTimesheetsClicked={() => {
              setIsTimesheetUserListDialogOpen(true)
            }}
            onEditInfoClicked={() => {
              setNavigateToEditInfo(true)
            }}
            onMemberCountClicked={() => {
              setNavigateToMembers(true)
            }}
            onStatusChanged={(statusKey: string) => {
              input?.changeStatus(statusKey)
            }}
            viewData={viewData}
          />
          {posts.map((postListItemViewData) => {
            return (
              <PostListItemView
                key={postListItemViewData.postKey}
                teamKey={teamKey}
                projectKey={projectKey}
                onCommentButtonClicked={onCommentButtonClicked}
                viewData={postListItemViewData}
                onPostMenuActionSelected={onPostMenuActionSelected}
                onPhotoClicked={onPhotoClicked}
                onPhotoViewed={onPhotoViewed}
                onBecameVisible={onBecameVisible}
                isOpenPostButtonVisible={true}
                isCommentButtonVisible={true}
                isAllPhotoAttachmentsVisible={false}
              />
            )
          })}
          <EmptyView icon={DashboardIcon} viewData={viewData?.empty} />
          {viewData?.hasMoreAtBottom === true && <LoadingView key={0} />}
        </>
      )}
      <ConfirmationDialog
        open={viewData?.deletePostDialog != null}
        onConfirm={() => input?.deletePostConfirmed()}
        onCancel={() => input?.deletePostCancelled()}
        dialogViewData={viewData?.deletePostDialog}
      />
      <ConfirmationDialog
        open={viewData?.deleteProjectDialog != null}
        onConfirm={() => input?.deleteProjectConfirmed()}
        onCancel={() => input?.deleteProjectCancelled()}
        dialogViewData={viewData?.deleteProjectDialog}
      />
      {navigateToClientKey && (
        <ClientEditDialog
          teamKey={teamKey}
          isOpen={navigateToClientKey != null}
          clientKey={navigateToClientKey}
          onSave={() => setNavigateToClientKey(null)}
          onCloseDialog={() => setNavigateToClientKey(null)}
        />
      )}
      {postEditDialogOptions && (
        <PostEditDialog
          teamKey={teamKey}
          projectKey={projectKey}
          postKey={postEditDialogOptions.postKey}
          actionKeyOnFirstLoad={postEditDialogOptions.actionKeyOnFirstLoad}
          isOpen={postEditDialogOptions !== null}
          onCloseDialog={onClosePostEditDialog}
        />
      )}
      {isTimesheetUserListDialogOpen && (
        <TimesheetUserListDialog
          teamKey={teamKey}
          projectKey={projectKey}
          isOpen={isTimesheetUserListDialogOpen}
          onUserClicked={(userKey) => {
            if (userKey) {
              setTimesheetUserKeyDialog(userKey)
            } else {
              setIsTimesheetUserListDialogOpen(false)
            }
          }}
        />
      )}
      {timesheetUserKeyDialog && (
        <TimesheetUserDialog
          teamKey={teamKey}
          projectKey={projectKey}
          userKey={timesheetUserKeyDialog}
          isOpen={timesheetUserKeyDialog != null}
          onClose={() => {
            setTimesheetUserKeyDialog(null)
          }}
        />
      )}
      <PostListItemGalleryView
        initialAttachmentKey={gallerySelectedAttachmentKey}
        viewData={galleryPostViewData}
        onPhotoViewed={onPhotoViewed}
        onClose={() => setGalleryPostKey(null)}
      />
    </NavigationBarPageLayout>
  )
}

export default ProjectPage
