import React, { useState } from "react"
import ListIcon from "@mui/icons-material/List"
import { Box, Button } from "@mui/material"

import * as shared from "probuild-shared"

import DocumentEditingView from "components/views/documents/editing/DocumentEditingView"
import NavigationBarDialog from "components/dialogs/NavigationBarDialog"
import DocumentEditingLineItemDialogs from "./DocumentEditingLineItemDialogs"
import DeleteActionButton from "components/views/generic/DeleteActionButton"

const DocumentEditRepeatDialog = ({
  teamKey,
  userKey,
  input,
  viewData,
  modalRow,
  setModalRow,
}: {
  teamKey: string
  userKey: string
  input: shared.com.probuildsoftware.probuild.library.documents.DocumentEditInput
  viewData: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentEditViewData
  modalRow: shared.com.probuildsoftware.probuild.library.documents.data.event.OpenRowEvent
  setModalRow: React.Dispatch<
    React.SetStateAction<shared.com.probuildsoftware.probuild.library.documents.data.event.OpenRowEvent | null>
  >
}) => {
  const [isSelectingLineItem, setIsSelectingLineItem] = useState<boolean>(false)
  const rootDocumentKey = viewData.info.documentAnswerKey
  const rowDocumentKey = modalRow.rowDocumentAnswerKey
  const openRowViewData = viewData.rowDocuments
    .asJsReadonlyMapView()
    .get(rowDocumentKey)
  const onDeleteClicked = () => {
    input.deleteRepeat(rootDocumentKey, modalRow.elementKey, modalRow.rowKey)
    setModalRow(null)
  }
  const onCloseDialog = () => setModalRow(null)
  return (
    <NavigationBarDialog
      isOpen={modalRow != null}
      onClose={onCloseDialog}
      title={openRowViewData?.info.name}
      barItems={
        <Button color="primary" variant="contained" onClick={onCloseDialog}>
          Done
        </Button>
      }
    >
      {openRowViewData?.loadLineItemButtonText && (
        <Box m={3}>
          <Button
            variant={"outlined"}
            style={{ width: "100%" }}
            color="primary"
            onClick={() => setIsSelectingLineItem(true)}
          >
            {openRowViewData?.loadLineItemButtonText}
            <ListIcon />
          </Button>
        </Box>
      )}
      <Box m={3}>
        <DocumentEditingView
          teamKey={teamKey}
          userKey={userKey}
          viewData={openRowViewData || null}
          input={input}
        />
        <DeleteActionButton text="Delete" onClick={onDeleteClicked} />
      </Box>
      <DocumentEditingLineItemDialogs
        teamKey={teamKey}
        rowDocumentKey={rowDocumentKey}
        input={input}
        isSelectingLineItem={isSelectingLineItem}
        setIsSelectingLineItem={setIsSelectingLineItem}
      />
    </NavigationBarDialog>
  )
}

export default DocumentEditRepeatDialog
