import { useCallback, useState } from "react"
import {
  Box,
  Button,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import Contacts from "@mui/icons-material/Contacts"

import ClientListDialog from "components/dialogs/ClientListDialog"
import ClientEditDialog from "components/dialogs/ClientEditDialog"

import * as shared from "probuild-shared"

const ClientSummaryView = ({
  teamKey,
  client,
  onClientChanged,
}: {
  teamKey: string
  client: shared.com.probuildsoftware.probuild.library.projects.data.view.ProjectEditClientViewData | null
  onClientChanged: (clientKey: string | null) => void
}) => {
  const [isSelectingClient, setIsSelectingClient] = useState<boolean>(false)
  const [isEditingClient, setIsEditingClient] = useState<boolean>(false)
  const [isCreatingClient, setIsCreatingClient] = useState<boolean>(false)
  const onCloseClientDialog = useCallback(() => {
    setIsEditingClient(false)
    setIsCreatingClient(false)
  }, [])
  const onAddClientClicked = () => {
    setIsSelectingClient(true)
  }
  const onClientClicked = (clientKey: string | null) => {
    setIsSelectingClient(false)
    if (clientKey) {
      onClientChanged(clientKey)
    }
  }
  const onCreateClientClicked = () => {
    setIsCreatingClient(true)
    setIsSelectingClient(false)
  }
  const onRemoveClientClicked = () => {
    onClientChanged(null)
  }
  const onSave = useCallback(
    (clientKey: string) => {
      onClientChanged(clientKey)
    },
    [onClientChanged]
  )
  return (
    client && (
      <Box>
        <InputLabel variant="standard" shrink={true}>
          {client.clientLabel}
        </InputLabel>
        <Box>
          {client.clientName && (
            <ListItemButton
              onClick={() => setIsEditingClient(true)}
              sx={{ pl: 0.5 }}
            >
              <ListItemText primary={client.clientName} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                <Contacts color="primary" />
              </ListItemIcon>
            </ListItemButton>
          )}
          {client.clientChangeButtonText && (
            <Button
              variant="contained"
              color="primary"
              onClick={onAddClientClicked}
              sx={{ mt: 1 }}
            >
              {client.clientChangeButtonText}
            </Button>
          )}
          {client.clientRemoveButtonText && (
            <Button
              sx={{ mt: 1, ml: 1 }}
              color="primary"
              onClick={onRemoveClientClicked}
            >
              {client.clientRemoveButtonText}
            </Button>
          )}
        </Box>
        {isSelectingClient && (
          <ClientListDialog
            teamKey={teamKey}
            isOpen={isSelectingClient}
            onClientSelected={onClientClicked}
            onCreateClicked={onCreateClientClicked}
          />
        )}
        {isCreatingClient && (
          <ClientEditDialog
            teamKey={teamKey}
            clientKey={undefined}
            isOpen={true}
            onCloseDialog={onCloseClientDialog}
            onSave={onSave}
          />
        )}
        {isEditingClient && (
          <ClientEditDialog
            teamKey={teamKey}
            clientKey={client.clientKey ?? undefined}
            isOpen={true}
            onCloseDialog={onCloseClientDialog}
            onSave={onSave}
          />
        )}
      </Box>
    )
  )
}

export default ClientSummaryView
