import { Button, styled } from "@mui/material"
import { Link } from "react-router-dom"

const DeleteButtonDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(2),
}))

const DeleteActionButton = ({
  text,
  to,
  onClick,
}: {
  text: string | null | undefined
  to?: string
  onClick?: () => void
}) => {
  if (!text) return null
  return (
    <DeleteButtonDiv>
      <Button
        component={to ? Link : "button"}
        to={to}
        onClick={onClick}
        variant="outlined"
        color="error"
        sx={{
          width: "100%",
          "@media (min-width:600px)": {
            minWidth: 400,
            width: "auto",
          },
        }}
      >
        {text}
      </Button>
    </DeleteButtonDiv>
  )
}

export default DeleteActionButton
