import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

function useProjectMemberListPreview({
  teamKey,
  projectKey,
}: {
  teamKey: string
  projectKey: string | undefined
}): [
  shared.com.probuildsoftware.probuild.library.projects.data.view.ProjectMemberListPreviewViewData | null
] {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && projectKey) {
      console.log(
        `Creating project member list preview presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} projectKey = ${projectKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.projects.ProjectMemberListPreviewPresenter(
        dependencyProvider,
        appUser,
        {
          projectKey,
        }
      )
    }
  }, [appUser, projectKey, dependencyProvider])
  const [viewData] = usePresenter(presenter)
  return [viewData]
}

export default useProjectMemberListPreview
