import { useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import SaveButton from "components/views/generic/SaveButton"
import useProjectMemberListEdit from "model/projects/useProjectMemberListEdit"
import UserListSectionView from "components/views/users/list/UserListSectionView"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import usePromptSafe from "model/navigation/usePromptSafe"
import LoadingModal from "components/views/generic/LoadingModal"
import paths from "model/utils/paths"

const ProjectMemberListEditPage = ({ teamKey }: { teamKey: string }) => {
  const { projectKey } = useParams()
  const [navigate, navigateSafeState] = useNavigateSafe()
  const location = useLocation()
  const observer = useMemo(() => {
    return {
      onNavigateBack() {
        navigate(-1)
      },
      onNavigateToProject(projectKey: string) {
        navigate(paths.project(teamKey, projectKey), { replace: true })
      },
    }
  }, [navigate, teamKey])
  const [viewData, input] = useProjectMemberListEdit({
    teamKey,
    projectKey,
    isNewProject: location.state?.isNewProject === true,
    observer,
  })
  usePromptSafe({
    when: viewData?.shouldConfirmDiscard === true,
    message: viewData?.discardConfirmationDialog?.message,
    navigateSafeState: navigateSafeState,
  })
  const sections = viewData?.sections?.asJsReadonlyArrayView() || []
  return (
    <NavigationBarPageLayout
      title={viewData?.title}
      barItems={
        <SaveButton
          text={viewData?.saveButtonTitle}
          isEnabled={true}
          onClick={() => input?.save()}
        />
      }
    >
      {sections.map((section) => {
        return (
          <UserListSectionView
            key={section.title}
            teamKey={teamKey}
            section={section}
            onUserSelected={(userKey: string) =>
              input?.toggleIsUserSelected(userKey)
            }
          />
        )
      })}
      <LoadingModal open={viewData?.isSaveInProgress === true} />
    </NavigationBarPageLayout>
  )
}

export default ProjectMemberListEditPage
