import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

const useClient = ({
  teamKey,
  clientKey,
  observer,
}: {
  teamKey: string
  clientKey: string | undefined
  observer: shared.com.probuildsoftware.probuild.library.clients.ClientEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.clients.data.view.ClientViewData | null,
  shared.com.probuildsoftware.probuild.library.clients.ClientInput | null
] => {
  const dependencyProvider = useDependencyProvider()
  const appUser = useAppUser({ teamKey })
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser) {
      console.log(
        `Creating client presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} clientKey = ${clientKey}`
      )
      const clientPresenter =
        new shared.com.probuildsoftware.probuild.library.clients.ClientPresenter(
          dependencyProvider,
          null,
          appUser,
          {
            clientKey,
          }
        )
      clientPresenter.input.startEditing()
      return clientPresenter
    }
  }, [appUser, clientKey, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useClient
