import { Navigate } from "react-router"
import { Typography } from "@mui/material"

import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import useTabs from "model/app/useTabs"
import paths from "model/utils/paths"

const TeamHomePage = ({ teamKey }: { teamKey: string }) => {
  const viewData = useTabs({ teamKey })
  if (viewData != null) {
    if (viewData.projects) {
      return <Navigate to={paths.projects(teamKey)} replace={true} />
    }
    if (viewData.timesheets) {
      return <Navigate to={paths.timesheets(teamKey)} replace={true} />
    }
    if (viewData.estimatesAndInvoices) {
      return <Navigate to={paths.documents(teamKey)} replace={true} />
    }
    if (viewData?.clients) {
      return <Navigate to={paths.clients(teamKey)} replace={true} />
    }
    if (viewData?.items) {
      return <Navigate to={paths.items(teamKey)} replace={true} />
    }
    if (viewData?.contracts) {
      return <Navigate to={paths.contracts(teamKey)} replace={true} />
    }
    if (viewData?.users) {
      return <Navigate to={paths.users(teamKey)} replace={true} />
    }
  }
  return (
    <NavigationBarPageLayout
      title={"Home"}
      showLoadingSpinner={viewData == null}
    >
      {viewData && (
        <Typography>
          You do not have permission to view any tabs of this business
        </Typography>
      )}
    </NavigationBarPageLayout>
  )
}

export default TeamHomePage
