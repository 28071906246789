import { styled } from "@mui/material/styles"
import { ButtonBase, Divider, Typography } from "@mui/material"
import { Dashboard } from "@mui/icons-material"

import * as shared from "probuild-shared"
import { useState } from "react"
import { Navigate } from "react-router-dom"
import paths from "model/utils/paths"

const ProjectContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  padding: theme.spacing(2),
}))

const ProjectButton = styled(ButtonBase)({
  width: "100%",
})

const DocumentEditingProject = ({
  teamKey,
  element,
}: {
  teamKey: string
  element: shared.com.probuildsoftware.probuild.library.documents.data.view.elements.ElementViewData
}) => {
  const [navigateToMembers, setNavigateToMembers] = useState<boolean>(false)
  const { projectKey, projectName, notAddedText } = element.project || {}
  if (navigateToMembers && projectKey) {
    return <Navigate to={paths.projectInfo(teamKey, projectKey)} />
  }
  return (
    <>
      {projectKey ? (
        <>
          <ProjectButton onClick={() => setNavigateToMembers(true)}>
            <ProjectContentDiv>
              <Typography fontWeight="bold">{projectName}</Typography>
              <Dashboard color="primary" />
            </ProjectContentDiv>
          </ProjectButton>
          <Divider />
        </>
      ) : (
        <Typography fontStyle="italic" sx={{ p: 2 }}>
          {notAddedText}
        </Typography>
      )}
    </>
  )
}

export default DocumentEditingProject
