import { useMemo } from "react"

import * as shared from "probuild-shared"

import useDependencyProvider from "model/utils/useDependencyProvider"
import useAppUser from "model/utils/useAppUser"
import usePresenter from "model/presenter/usePresenter"

function useProjectMemberListEdit({
  teamKey,
  projectKey,
  isNewProject,
  observer,
}: {
  teamKey: string
  projectKey: string | undefined
  isNewProject: boolean
  observer: shared.com.probuildsoftware.probuild.library.projects.ProjectMemberListEditEventObserver
}): [
  shared.com.probuildsoftware.probuild.library.projects.data.view.ProjectMemberListEditViewData | null,
  shared.com.probuildsoftware.probuild.library.projects.ProjectMemberListEditInput | null
] {
  const appUser = useAppUser({ teamKey })
  const dependencyProvider = useDependencyProvider()
  const presenter = useMemo(() => {
    if (dependencyProvider && appUser && projectKey) {
      console.log(
        `Creating project member list edit presenter teamKey = ${appUser.teamKey} userKey = ${appUser.userKey} projectKey = ${projectKey}`
      )
      return new shared.com.probuildsoftware.probuild.library.projects.ProjectMemberListEditPresenter(
        dependencyProvider,
        appUser,
        {
          projectKey,
          isNewProject,
        }
      )
    }
  }, [appUser, projectKey, isNewProject, dependencyProvider])
  return usePresenter(presenter, observer)
}

export default useProjectMemberListEdit
