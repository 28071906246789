import React, { useContext, useEffect } from "react"
import { styled } from "@mui/material/styles"

import InfiniteScroll from "react-infinite-scroller"

import NavigationBar from "components/views/generic/NavigationBar"
import useAccountsList from "model/app/useAccountsList"
import NavigationDrawerContext from "contexts/NavigationDrawerContext"
import LoadingView from "../LoadingView"

const ContentDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
})

const StyledNavigationBar = styled(NavigationBar)({
  width: "100%",
})

const StyledInfiniteScroll = styled(InfiniteScroll)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
  maxWidth: "870px",
}))

const Root = styled("main")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  minWidth: 0,
}))

const NavigationBarPageLayout = ({
  title,
  barItems,
  createButtons,
  children,
  loadMoreAtBottom,
  hasMore,
  showLoadingSpinner,
  searchPlaceholder,
  searchOnChange,
  searchAutoFocus,
}: {
  title?: string | null | undefined
  barItems?: React.ReactNode
  createButtons?: { to: string; text: string | undefined | null }[]
  children: React.ReactNode
  loadMoreAtBottom?: () => void
  hasMore?: boolean
  showLoadingSpinner?: boolean

  searchPlaceholder?: string
  searchOnChange?: (text: string) => void
  searchAutoFocus?: boolean
}) => {
  const [viewData] = useAccountsList()
  const { toggleNavigationDrawer } = useContext(NavigationDrawerContext)
  const teamName = viewData?.currentAccount?.teamName
  useEffect(() => {
    if (title) {
      document.title = `${title} | ${teamName}`
    }
  }, [title, teamName])
  return (
    <Root>
      <StyledNavigationBar
        toggleNavigationDrawer={toggleNavigationDrawer}
        title={title}
        barItems={barItems}
        createButtons={createButtons}
        searchPlaceholder={searchPlaceholder}
        searchOnChange={
          searchOnChange && ((text: string) => searchOnChange(text))
        }
        searchAutoFocus={searchAutoFocus}
      />
      {showLoadingSpinner ? (
        <LoadingView />
      ) : (
        <ContentDiv>
          <StyledInfiniteScroll
            threshold={250}
            pageStart={0}
            loadMore={loadMoreAtBottom || (() => {})}
            hasMore={hasMore}
            useWindow={false}
            loader={<LoadingView />}
          >
            {children}
          </StyledInfiniteScroll>
        </ContentDiv>
      )}
    </Root>
  )
}

export default NavigationBarPageLayout
