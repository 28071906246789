import { useMemo, useState } from "react"
import { useParams } from "react-router-dom"

import * as shared from "probuild-shared"

import DocumentEditingView from "components/views/documents/editing/DocumentEditingView"
import useDocumentEditViewData from "model/documents/editing/useDocumentEditViewData"
import DocumentEditRepeatDialog from "components/views/documents/editing/DocumentEditRepeatDialog"
import LoadingModal from "components/views/generic/LoadingModal"
import NavigationBarPageLayout from "components/views/layouts/NavigationBarPageLayout"
import SaveButton from "components/views/generic/SaveButton"
import useUserKey from "model/utils/useUserKey"
import useSnackbar from "model/snackbar/useSnackbar"
import paths from "model/utils/paths"
import DocumentEditingMarkupOrDiscountDialog from "components/views/documents/editing/DocumentEditingMarkupOrDiscountDialog"
import useNavigateSafe from "model/navigation/useNavigateSafe"
import usePromptSafe from "model/navigation/usePromptSafe"

const DocumentEditPage = ({ teamKey }: { teamKey: string }) => {
  const { documentKey, documentType } = useParams()
  const isNewDocument = !documentKey
  const userKey = useUserKey(teamKey)
  const [modalRow, setModalRow] =
    useState<shared.com.probuildsoftware.probuild.library.documents.data.event.OpenRowEvent | null>(
      null
    )
  const [navigate, navigateSafeState] = useNavigateSafe()
  const showSnackbar = useSnackbar()
  const observer = useMemo(() => {
    return {
      onOpenRowEvent(
        event: shared.com.probuildsoftware.probuild.library.documents.data.event.OpenRowEvent
      ) {
        setModalRow(event)
      },
      onSaveSuccess(
        message: string,
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData
      ) {
        showSnackbar(message, "success")
        const documentPreviewPath = paths.document(
          teamKey,
          metadata.documentAnswerKey
        )
        if (isNewDocument) {
          navigate(documentPreviewPath, { replace: true })
        } else {
          navigate(-1)
        }
      },
      onSaveError(message: string) {
        showSnackbar(message, "error")
      },
      onDeleteSuccess(message: string) {},
      onDeleteError(message: string) {},
      onScrollToElementEvent() {},
      onWorkflowCreatedEvent() {},
      onCopyCreated(
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData
      ) {},
      onConversionToInvoice(
        metadata: shared.com.probuildsoftware.probuild.library.documents.data.view.DocumentMetadataViewData
      ) {},
    }
  }, [teamKey, navigate, showSnackbar, isNewDocument])
  const [viewData, input] = useDocumentEditViewData({
    teamKey,
    documentKey,
    documentType,
    observer,
  })
  const saveChanges = () => {
    input?.saveDocument()
  }
  usePromptSafe({
    when: viewData?.info.isModified === true,
    message: viewData?.discardConfirmationDialog?.message || "",
    navigateSafeState: navigateSafeState,
  })
  return (
    <NavigationBarPageLayout
      title={viewData?.info.name}
      barItems={
        <>
          <SaveButton
            text={viewData?.saveButtonText}
            isEnabled={viewData?.info.isModified}
            onClick={saveChanges}
          />
        </>
      }
      showLoadingSpinner={!viewData}
    >
      {viewData && (
        <>
          <DocumentEditingView
            teamKey={teamKey}
            userKey={userKey}
            viewData={viewData}
            input={input}
          />
          {input && modalRow && userKey && (
            <DocumentEditRepeatDialog
              teamKey={teamKey}
              userKey={userKey}
              input={input}
              viewData={viewData}
              modalRow={modalRow}
              setModalRow={setModalRow}
            />
          )}
          {input && viewData.markupOrDiscountDialog && (
            <DocumentEditingMarkupOrDiscountDialog
              input={input}
              viewData={viewData.markupOrDiscountDialog}
            />
          )}
          <LoadingModal open={viewData.isSaveInProgress === true} />
        </>
      )}
    </NavigationBarPageLayout>
  )
}

export default DocumentEditPage
